import { useCallback, useContext, useEffect, useState } from 'react';
import { capitalize } from 'lodash';

import notify from 'notify';
import { getUsers } from 'api/users';
import { mapUsers } from 'views/CalendarPage/mapping';
import { tryGetFirstError } from 'utils/requests';
import { DropdownOption } from 'components';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import {
  completeTask as completeTaskRequest,
  deleteEvent,
  deleteTask
} from 'views/CalendarPage/api';
import WorkspaceContext from '../WorkspaceContext';
import {
  getWorksapceCalendarItems,
  getWorksapceDeadlinesXP,
  getEmailTemplateDataRequest,
  getTaskNotes,
  deleteTaskNotes,
  addTaskNote
} from '../api';

export default () => {
  const { listing, listingId, setWorkspaceTab, setEmailDocumentsData, setEmailDocumentsDataStep,setEmailDocumentsDataMembersNoDocument } = useContext(WorkspaceContext);
  const [tasks, setTasks] = useState<CalendarItem[]>();
  const [deadlines, setDeadlines] = useState<WorkspaceDeadline[]>();
  const [calendarEdit, setCalendarEdit] = useState<Partial<CalendarItem>>();
  const [calendarDelete, setCalendarDelete] = useState<CalendarItem>();
  const [documentsOptions, setDocumentsOptions] = useState<DropdownOption[]>([]);
  const [usersOptions, setUsersOptions] = useState<DropdownOption[]>([]);
  const [addOrderModalData, setAddOrderModalData] = useState<CalendarItem>();
  const [taskNotesModalOpen, setTaskNotesModalOpen] = useState<boolean>(false);
  const [notesData, setAllTaskNotes] = useState([]);
  const [taskIdNotes, setTaskIdNotes] = useState<number>();
  const [openTemplateSheet, setOpenTemplateSheet] = useState<string>("");

  const openTemplateSheetPopup = async (emailTemplateId) => {
    console.log(emailTemplateId);
    try {
      const { templateData } = await getEmailTemplateDataRequest(listingId, emailTemplateId);
      setOpenTemplateSheet(templateData.html);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const addTaskNoteSet = async (taskId,note) => {
    try {
      await addTaskNote({listingId,taskId,note});
      const { notesData } = await getTaskNotes(listingId,taskId);
      setAllTaskNotes(notesData);

    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const deleteTaskNoteSet = async (taskId,noteId) => {
    try {
      await deleteTaskNotes(listingId,noteId);
      const { notesData } = await getTaskNotes(listingId,taskId);
      setAllTaskNotes(notesData);

    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const getWorkspaceTaskNotes = async taskId => {
    try {
      setTaskIdNotes(taskId);
      const { notesData } = await getTaskNotes(listingId,taskId);
      setAllTaskNotes(notesData);
      setTaskNotesModalOpen(true);

    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const completeTask = async event => {
    try {
      await completeTaskRequest(event);
      fetchCalendarItems();
      notify('Task was successfully updated');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const getEmailTemplateData = async emailTemplateId => {
    try {
      const { templateData } = await getEmailTemplateDataRequest(listingId, emailTemplateId);
      if(templateData.document_ids==null){
        console.log("set zero ");
        setEmailDocumentsDataMembersNoDocument(0);
      }
      setEmailDocumentsData(templateData);
      setWorkspaceTab('documents');
      setEmailDocumentsDataStep(1);

    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };
  

  const deleteCalendarItem = async event => {
    try {
      const deleteRequest = event?.dateType === 'task' ? deleteTask : deleteEvent;
      await deleteRequest(event);
      fetchCalendarItems();
      notify(`${capitalize(calendarDelete?.dateType)} successfully deleted`);
      setCalendarDelete(undefined);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const fetchCalendarItems = useCallback(async () => {
    let newDeadlines: WorkspaceDeadline[] = [];
    const getCalendarItems = async () => {
      try {
        const { tasks, deadlines, documents } = await getWorksapceCalendarItems(listingId);
        setTasks(tasks);
        newDeadlines = [...newDeadlines, ...deadlines];
        // setDeadlines(deadlines);
        setDocumentsOptions(documents);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    };

    const getDeadlinesXP = async () => {
      try {
        const deadlines = await getWorksapceDeadlinesXP(listingId);
        newDeadlines = [...newDeadlines, ...deadlines];
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    };

    await Promise.all([getCalendarItems(), getDeadlinesXP()]);

    setDeadlines(newDeadlines.sort((a, b) => a.date - b.date));
  }, [listingId]);

  useEffect(() => {
    (async () => {
      try {
        const users = await getUsers();
        const mappedData = mapUsers(users);
        setUsersOptions(mappedData);
      } catch (err) {
        notify(err.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (!listingId) return;
    fetchCalendarItems();
  }, [fetchCalendarItems, listingId]);

  return {
    listing,
    listingId,
    tasks,
    deadlines,
    calendarEdit,
    setCalendarEdit,
    calendarDelete,
    setCalendarDelete,
    documentsOptions,
    usersOptions,
    addOrderModalData,
    setAddOrderModalData,
    completeTask,
    fetchCalendarItems,
    deleteCalendarItem,
    getEmailTemplateData,
    getWorkspaceTaskNotes,
    taskNotesModalOpen,
    setTaskNotesModalOpen,
    notesData,
    addTaskNoteSet,
    deleteTaskNoteSet,
    taskIdNotes,
    openTemplateSheet,
    openTemplateSheetPopup,
    setOpenTemplateSheet
  };
};
