import React, { useContext, useEffect, useMemo, useState } from 'react';
import useAuthContext from 'hooks/useAuthContext';
import { Button, Modal, ModalXpMembers , ModalMemberSheet } from 'components';
import styled from '@emotion/styled';
import notify from 'notify';
import useLayout from 'hooks/useLayout';
import { tryGetFirstError } from 'utils/requests';
import { updateParticipant, removeParticipant, getXpMembers, getEmailTemplateDataRequest } from 'workspace/api';
import WorkspaceContext from 'workspace/WorkspaceContext';
import MembersTableItem from './MembersTableItem';
import MobileMembersTableItem from './MobileMembersTableItem';
import EditParticipantForm from './EditParticipantForm';
import EditMembersMenu from './EditMembersMenu';

const WorkspaceMembersTab = () => {
  const { listingId, members, setMembers, fetchMembers, isLinkedListing,setEmailDocumentsDataStep,setWorkspaceTab,setEmailDocumentsDataMembersNoDocument ,setEmailDocumentsData} = useContext(WorkspaceContext);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [memberSheet, setOpenMemberSheet] = useState<string>("");
  const [openXpForm, setOpenXpForm] = useState<[]>();
  const [editParticipantData, setEditParticipantData] = useState<WorkspaceMember>();
  const [deleteParticipantData, setDeleteParticipantData] = useState<number | null>();
  const layout = useLayout();

  const roleGroups = useMemo(() => {
    const group = {};
    group['Sell Side'] = members.filter(member => member.role?.includes('Seller'));
    group['Buy Side'] = members.filter(member => member.role?.includes('Buyer'));
    // eslint-disable-next-line dot-notation
    group['Vendor'] = [
      ...members.filter(member => member.role?.includes('Vendor')),
      ...members.filter(member => member.role?.includes('Condo/homeownerAssociation')),
      ...members.filter(member => member.role?.includes('Lender')),
      ...members.filter(member => member.role?.includes('TitleCompany')),
      ...members.filter(member => member.role?.includes('Surveyor')),
    ];
    return group;
  }, [members]);

  const editParticipant = async (values: WorkspaceMember) => {
    try {
      const isEditing = Boolean(editParticipantData);
      const formValues = isEditing
        ? { ...values, peopleId: editParticipantData?.id, listingId }
        : { ...values, listingId };
      await updateParticipant(formValues);
      const notifyMessage = isEditing
        ? 'Participant was successfully updated'
        : 'New participant was successfully added';
      setOpenEditForm(false);
      fetchMembers();
      setEditParticipantData(undefined);
      notify(notifyMessage);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const { authData } = useAuthContext();

  const openMessageConsole = (member: WorkspaceMember) => {
    setEmailDocumentsData([]);
    setEmailDocumentsDataMembersNoDocument(member.id);
    setEmailDocumentsDataStep(2);
    setWorkspaceTab('documents');
  };

  const showEditForm = (member: WorkspaceMember) => {
    setEditParticipantData(member);
    setOpenEditForm(true);
  };

  const closeEditForm = () => {
    setOpenEditForm(false);
    setEditParticipantData(undefined);
  };

  const closeDeleteParticipant = () => setDeleteParticipantData(null);

  const handleRemoveParticipant = async () => {
    try {
      const memberId = deleteParticipantData;
      await removeParticipant(listingId, memberId);
      setMembers(value => value.filter(item => item.id !== memberId));
      closeDeleteParticipant();
      notify('Participant was successfully removed');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const openMemberSheet = async () => {
    try {
      const { templateData } = await getEmailTemplateDataRequest(listingId, 3);
      setOpenMemberSheet(templateData.html);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleGetXpMembers = async () => {
    try {
      const { peopleData } = await getXpMembers(listingId);
      setOpenXpForm(peopleData);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    if (!members.length) fetchMembers();
  }, [fetchMembers, members.length]);

  const ListItemComponent = useMemo(
    () => (layout === 'mobile' ? MobileMembersTableItem : MembersTableItem),
    [layout]
  );

  return (
    <>
      <StyledWorkspaceMembersTab>
        {!openEditForm && (
          <>
            {authData?.superAdmin && <div className="members__btn-group add-new">
              <Button onClick={() => openMemberSheet()} className="members__btn">
                Member Sheet
              </Button>
              {isLinkedListing && (
                <Button onClick={() => handleGetXpMembers()} className="members__btn">
                  {layout === 'mobile' ? 'XP' : `Sync with XP`}
                </Button>
              )}
              <Button onClick={() => setOpenEditForm(true)} className="members__btn">
                Add new
              </Button>
              
            </div>}
            {members.length !== 0 && (
              <table>
                {Object.keys(roleGroups)
                  .filter(group => roleGroups[group].length)
                  .map(group => (
                    <React.Fragment key={group}>
                      <tbody>
                        <tr>
                          <td key={group} className="members__title" colSpan={7}>
                            {group}:
                          </td>
                        </tr>
                      </tbody>
                      {roleGroups[group].map(member => (
                        <ListItemComponent
                          key={`${member.id}-${member.role}`}
                          data={member}
                          message={() => openMessageConsole(member)}
                          edit={() => showEditForm(member)}
                          remove={() => setDeleteParticipantData(member.id)}
                        />
                      ))}
                    </React.Fragment>
                  ))}
              </table>
            )}
          </>
        )}
        {openEditForm && (
          <EditMembersMenu
            title={`${editParticipantData ? 'Edit' : 'Add New'} Member`}
            onClose={closeEditForm}>
            <EditParticipantForm
              onClose={closeEditForm}
              values={editParticipantData}
              listingId={listingId}
              submit={editParticipant}
            />
          </EditMembersMenu>
        )}
      </StyledWorkspaceMembersTab>
      <StyledModal
        modalTitle="Remove participant"
        open={Boolean(deleteParticipantData)}
        onClose={closeDeleteParticipant}
        className="delete-member">
        <p>Are you sure you want to remove this participant? This action can’t be undone.</p>
        <Button secondary onClick={closeDeleteParticipant}>
          Discard
        </Button>
        <Button onClick={handleRemoveParticipant}>Remove</Button>
      </StyledModal>
      {openXpForm && openXpForm.length>0 && 
      <StyledModalXpMembers
        modalTitle="Sync With XchangePlus"
        open={Boolean(openXpForm)}
        people={openXpForm}
        listingId={listingId}
        onClose={() => {setOpenXpForm([]);fetchMembers();}}
      >
      </StyledModalXpMembers>
      }
      {memberSheet && memberSheet.length>0 && 
      <StyledModalMemberSheet
        modalTitle="Email Template Data"
        open={Boolean(memberSheet.length>0)}
        html={memberSheet}
        listingId={listingId}
        onClose={() => {setOpenMemberSheet("")}}
      >
      </StyledModalMemberSheet>
      }
    </>
  );
};

export default WorkspaceMembersTab;

const StyledModalXpMembers = styled(ModalXpMembers)`
  .modal-content {
    max-width: 450px;
    width:100%;
  }
`;

const StyledModalMemberSheet = styled(ModalMemberSheet)`
  .modal-content {
    max-width: 650px;
    width:100%;
  }
`;

const StyledWorkspaceMembersTab = styled.div`
  padding: 0 0 16px;
  flex: 1;
  table {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    border-collapse: collapse;

    th {
      font-weight: normal;
      color: #8b8b8b;
      fill: #8b8b8b;
      line-height: 16px;
      text-align: left;
      padding: 16px 0;
    }

    th,
    td {
      &:first-of-type {
        width: 32px;
        text-align: center;
        &.members__title {
          padding-top: 32px;
          width: 100%;
          text-align: left;
        }
      }
    }

    thead:first-of-type th {
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
  }
  .members {
    &__title {
      margin: 8px 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      padding-bottom: 12px;
    }
    &__btn-group {
      padding: 16px 0 0;
      display: flex;
      margin: 12px 0;
      &.add-new {
        justify-content: flex-end;
        margin: 12px 0 -24px;
      }
      &.user-buttons {
        margin: 0;
        padding: 16px 0;
      }
      &.mobile {
        margin: 0 0 0 8px;
      }
      .button ~ .button {
        margin-left: 16px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .members {
      &__btn-group {
        &.add-new {
          margin: 12px 0 -16px;
        }
      }
      &__btn {
        width: 100%;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .button {
    width: 100%;
    height: 40px;
  }
  .button + .button {
    margin-top: 16px;
  }
`;
