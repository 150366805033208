import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { Button, Checkbox, Dropdown } from '@xchange/uikit';
import { InputCurrency } from 'components';
interface EarnestMoneyFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  data?: { [key: string]: string | number; };
  onCancel: () => void;
  onSubmit: (values: { [key: string]: any; }) => void;
}

const beholdingOptions = [
  { value: 'seller_attorney', text: 'Seller Attorney' },
  { value: 'seller_broker', text: 'Seller Broker' },
  { value: 'buyer_attorney', text: 'Buyer Attorney' },
  { value: 'buyer_broker', text: 'Buyer Broker' },
  { value: 'title_company', text: 'Title Company' }
];

const EarnestMoneyForm: React.FC<EarnestMoneyFormProps> = ({
  data = {},
  onCancel,
  onSubmit
}) => {
  const {
    control,
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      em_role_specific: data.em_role_specific,
      earnest_money_1_deposit: data.earnest_money_1_deposit,
      earnest_money_2_deposit: data.earnest_money_2_deposit,
      earnest_money_3_deposit: data.earnest_money_3_deposit,
      earnest_money_4_deposit: data.earnest_money_4_deposit,
      earnest_money_1_deposited: data.earnest_money_1_deposited,
      earnest_money_2_deposited: data.earnest_money_2_deposited,
      earnest_money_3_deposited: data.earnest_money_3_deposited,
      earnest_money_4_deposited: data.earnest_money_4_deposited,
    }
  });

  return (
    <StyledEarnestMoneyForm onSubmit={handleSubmit(onSubmit)} className="em-form">
      <Controller
        control={control}
        name="em_role_specific"
        render={({ field, formState: { errors } }) => (
          <Dropdown
            options={beholdingOptions}
            error={errors.em_role_specific?.message}
            label="Held by"
            placeholder="Select"
            {...field}
          />
        )}
      />
      <br />
      <div className="em-form__row">
        <Controller
          control={control}
          name="earnest_money_1_deposit"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="1st Installment"
              placeholder="Enter"
              error={errors.earnest_money_1_deposit?.message}
              {...field}
            />
          )}
        />
        <Checkbox
          {...register('earnest_money_1_deposited')}
          label="Deposited"
        />
      </div>

      <div className="em-form__row">
        <Controller
          control={control}
          name="earnest_money_2_deposit"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="2nd Installment"
              placeholder="Enter"
              error={errors.earnest_money_2_deposit?.message}
              {...field}
            />
          )}
        />
        <Checkbox
          {...register('earnest_money_2_deposited')}
          label="Deposited"
        />
      </div>

      <div className="em-form__row">
        <Controller
          control={control}
          name="earnest_money_3_deposit"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="3rd Installment"
              placeholder="Enter"
              error={errors.earnest_money_3_deposit?.message}
              {...field}
            />
          )}
        />
        <Checkbox
          {...register('earnest_money_3_deposited')}
          label="Deposited"
        />
      </div>

      <div className="em-form__row">
        <Controller
          control={control}
          name="earnest_money_4_deposit"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="4th Installment"
              placeholder="Enter"
              error={errors.earnest_money_4_deposit?.message}
              {...field}
            />
          )}
        />

        <Checkbox
          {...register('earnest_money_4_deposited')}
          label="Deposited"
        />
      </div>
      <div className="button-group">
        <Button secondary onClick={onCancel}>Cancel</Button>
        <Button>Update</Button>
      </div>
    </StyledEarnestMoneyForm>
  );
};
export default EarnestMoneyForm;

const StyledEarnestMoneyForm = styled.form`
  margin-bottom: auto;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  .input {
    display: block;
    margin-bottom: 16px;
  }
  .dropdown {
    width: 100%;
    margin-bottom: 4px;
  }
  .button-group {
    margin-top: 24px;
    grid-column: span 2;
    .button + .button {
      margin-left: 12px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    .em-form__row {
      margin-bottom: 16px;
    }
  }
`;
